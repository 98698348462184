import React, { useEffect } from "react";
import useAuth from "./hooks/useAuth";
import { ROLES } from "./data/Constants";



// Ezoic
// const AdsComponent = ({dataAdSlot, dataAdFormat = "auto", style = {}, isFullWidth = "true", classNames }) => {
//   const { auth } = useAuth();
//   const mergedStyles = { display: "block", ...style };

//   useEffect(() => {
//     const handleEzoicLoad = () => {
//       try {
//         const ezoic = window.ezstandalone;
//         if (ezoic) {
//           ezoic.define(103, 104, 105, 106);
//           if (!ezoic.enabled) {
//             ezoic.enable();
//             ezoic.display();
//             ezoic.refresh();
//           }
//         } else {
//           setTimeout(handleEzoicLoad, 500);
//         }
//       } catch (ex) {
//         console.error("Error with Ezoic:", ex);
//       }
//     };

//     if(!!auth?.user?.roles && auth?.user?.roles?.includes(ROLES.Premium)) return;
//     handleEzoicLoad();
//   }, [auth]);

//   // do not render ads if user is localhost
//   if (window.location.hostname === "localhost") {
//     return <div>Ad not loaded in localhost</div>;
//   }

//   return (
//     <>
//       <span className="text-gray-400">Advertisement</span>
//       <div id="ezoic-pub-ad-placeholder-104"></div>
//     </>
//   );
// };


const AdsComponent = ({dataAdSlot, dataAdFormat = "auto", style = {}, isFullWidth = "true", classNames }) => {
  const { auth } = useAuth();
  const mergedStyles = { display: "block", ...style };

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  if (auth?.user?.roles?.includes(ROLES.Premium)) {
    return null;
  }


  // do not render ads if user is localhost
  // if (window.location.hostname === "localhost") {
  //   return <div>Ad not loaded in localhost</div>;
  // }

  return (
    <>
      <span className="text-gray-400">Advertisement</span>
      <ins
        className={"adsbygoogle" + (classNames ? " " + classNames : "")}
        style={mergedStyles}
        data-ad-client="ca-pub-9400409503461204"
        data-ad-slot={dataAdSlot}
        data-ad-format={dataAdFormat}
        data-full-width-responsive={isFullWidth}
      ></ins>
      {/* <div id="container-2d219fd6fdbbf3643d8e3b1b22806434"></div> */}
      {/* <div id="ezoic-pub-ad-placeholder-104"></div> */}
    </>
  );
};

export function VerticalAdUnit(){
  return (
    <div className="font-light text-gray-500 text-xs p-1">
      <div className="mx-2 min-h-[60px] w-full">
        <AdsComponent
          style={{ display: 'block' }}
          dataAdFormat="auto"
          isFullWidth="true"
          dataAdSlot="8882996600" // Responsive Block
        />
      </div>
    </div>
  );
}

export default AdsComponent;
